// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---packages-ralves-gatsby-theme-site-copy-src-templates-posts-index-js": () => import("./../../../packages/@ralves/gatsby-theme-site-copy/src/templates/posts/index.js" /* webpackChunkName: "component---packages-ralves-gatsby-theme-site-copy-src-templates-posts-index-js" */),
  "component---packages-ralves-gatsby-theme-site-copy-src-templates-posts-item-js": () => import("./../../../packages/@ralves/gatsby-theme-site-copy/src/templates/posts/item.js" /* webpackChunkName: "component---packages-ralves-gatsby-theme-site-copy-src-templates-posts-item-js" */),
  "component---node-modules-talves-gatsby-theme-site-og-src-pages-og-js": () => import("./../../../node_modules/@talves/gatsby-theme-site-og/src/pages/og.js" /* webpackChunkName: "component---node-modules-talves-gatsby-theme-site-og-src-pages-og-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-old-index-js": () => import("./../src/pages/old_index.js" /* webpackChunkName: "component---src-pages-old-index-js" */),
  "component---src-pages-test-js": () => import("./../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

