module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@talves/gatsby-theme-site-provider/gatsby-browser.js'),
      options: {"plugins":[],"wrapRootElement":false},
    },{
      plugin: require('../../../node_modules/@talves/gatsby-theme-site-provider/gatsby-browser.js'),
      options: {"plugins":[],"wrapRootElement":true},
    }]
